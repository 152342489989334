<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-hover v-slot:default="{ hover }" close-delay="200">
        <v-card
          v-on="on"
          flat
          :elevation="hover ? 20 : 0"
          class="mx-auto"
          height="500"
        >
          <v-img v-if="videoName === 'video1'" class="white--text align-end" height="250px" src="@/assets/Home/video1.png"></v-img>
          <v-img v-if="videoName === 'video2'" class="white--text align-end" height="250px" src="@/assets/Home/video2@2x.png"></v-img>
          <v-img v-if="videoName === 'video3'" class="white--text align-end" height="250px" src="@/assets/Home/video3@2x.png"></v-img>
          <v-img v-if="videoName === 'video4'" class="white--text align-end" height="250px" src="@/assets/Home/video3@2x.png"></v-img>
          <v-img v-if="videoName === 'video5'" class="white--text align-end" height="250px" src="@/assets/Home/video5@2x.png"></v-img>
          
          <v-card-text>
            <v-card-text class="text--primary">
              <div>{{title}}</div>

              <div>{{detail}}</div>
            </v-card-text>

            <v-card-actions>
              <v-btn color="orange" text>
                Watch Video
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-hover>
    </template>
    <v-card>
      <ModelTitle :title="title" @close="dialog = false" />
      <div class="text-center">
        <youtube
        :video-id="videoId"
        ref="youtube"
        height="500" :player-vars="playerVars"
      ></youtube>
      </div>
      
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["videoId", 'title', 'detail', 'videoName'],
  data() {
    return {
      dialog: false,
      playerVars: {
        autoplay: 1
      }
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$refs.youtube.player.playVideo();
      } else {
        this.$refs.youtube.player.stopVideo()
      }
    }
  }
};
</script>

<style scoped></style>
