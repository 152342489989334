<template>
  <v-card flat>
    <v-card-title>
      <v-row>
        <v-col>
          <div class="text-center">Our Features</div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row align="start" justify="center">
        <v-col>
          <div class="text-center">
            <img src="@/assets/Home/scanner.png" height="70" alt="" />
            <div class="subtitle-1 font-weight-bold">Inventory Management</div>
            <div>Make your products work for you. Improve efficiency, gain visibility into your stock, and automate your hardest jobs.</div>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <img src="@/assets/Home/bill.png" height="70" alt="" />
            <div class="subtitle-1 font-weight-bold">Create Invoice</div>
            <div>
              Create professional-looking invoice and packing slip to email them directly from your system to your customer 
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <img src="@/assets/Home/crm.png" height="70" alt="" />
            <div class="subtitle-1 font-weight-bold">CRM</div>
            <div>
              Build your customer database, grow repeat business, and increase shopper spend with a custom loyalty program.
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <img src="@/assets/Home/billing.png" height="70" alt="" />
            <div class="subtitle-1 font-weight-bold">Order Estimate</div>
            <div>
              Estimate order helps you make sure what your customer needs before you create an invoice.
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <img src="@/assets/Home/filter.png" height="70" alt="" />
            <div class="subtitle-1 font-weight-bold">Leads Management</div>
            <div>
              Don't lose any new customers by keeping track of incoming leads.
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <img
              src="@/assets/Home/commerce-and-shopping.png"
              height="70"
              alt=""
            />
            <div class="subtitle-1 font-weight-bold">Track Serial Numbers</div>
            <div>
              Don't lose any more money by refunding the wrong items. Track each item with serial numbers.
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <img src="@/assets/Home/sync.png" height="70" alt="" />
            <div class="subtitle-1 font-weight-bold">Complete Cloud Base</div>
            <div>Cloud base means you can use software from anywhere from any device.</div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>

<style scoped></style>
