<template>
  <div>
    <v-app-bar app flat clipped-left dark color="#F0C316">
      <!-- <v-app-bar-nav-icon @click.stop="drawerToggle" /> -->

      <v-toolbar-title
        class="mr-12 align-center pointer"
        @click="$vuetify.goTo('#home')"
      >
        <span
          class="pl-2 mr-2 font-weight-light"
          v-if="!$vuetify.breakpoint.xsOnly"
          style="color: white; font-size: 25px;"
          >smpl <span class="font-weight-black black--text">sale</span>
        </span>
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-btn text @click="$router.push('/pricing')">
        Pricing
      </v-btn>
      <v-btn text @click="$vuetify.goTo('#video')">
        Video
      </v-btn>
      <v-divider vertical> </v-divider>
      <v-btn text @click="signup">
        Sign Up
      </v-btn>

      <v-btn text @click="login">
        Login
      </v-btn>
    </v-app-bar>
    <v-content>
      <!-- <v-card dark tile>
        <v-card-text>
          Need HELP CALL US
        </v-card-text>
      </v-card> -->
      <v-img id="home" src="@/assets/Home/homebg.png" style="height: 500px">
        <v-row align="center" justify="center" style="height: 100%;">
          <v-col class="text-center" cols="12">
            <h1>
              <span style="font-family: 'Sacramento'"
                >Business is complicated</span
              >
              <span> POS should be simple</span>
            </h1>
            <h1 class="display-1 font-weight-thin mb-4">
              smpl<span class="font-weight-bold">sale</span>
            </h1>
            <h4 class="title">
              Inventory | CRM | Lead | Cloud Base | Estimate | Expenses
            </h4>
            <h4 class="subheading">
              Everything you need to run a wholesale distribution
            </h4>
            <div class="mt-5 title">
              <div class="mb-3">Try for free, First month on Us</div>
              <v-btn large @click="signup" color="success"
                >Sign Up Now</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-img>
      <Features />
      <div style="background-color: white;">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card tile flat>
              <v-img src="@/assets/Home/screenshot1.png" style="height: 25%;">
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card flat>
              <v-card-text>
                <v-row class="pa-10">
                  <v-col cols="12">
                    <div class="headline font-weight-black">
                      Create a Customer Profile
                    </div>
                    <div class="title font-weight-regular mt-2">
                      Customer details are automatically stored under their profile where you can view their purchase history, loyalty, and account balances.

                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="headline font-weight-black">
                      Add new customers directly at the point of sale
                    </div>
                    <div class="title font-weight-regular mt-2">
                      Save customer information seamlessly during sales and rapidly build your customer database. Capture customer details with the option to email them their Invoice, packing slip, and statement.

                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="headline font-weight-black">Merge Customer</div>
                    <div class="title font-weight-regular mt-2">
                      Merge customer features allow you to merge 2 customer sales and balance into one.
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card flat>
              <v-card-text>
                <v-row class="pa-10">
                  <v-col cols="12">
                    <div class="headline font-weight-black">
                      Inventory Control
                    </div>
                    <div class="title font-weight-regular mt-2">
                      Make better-informed decisions about your purchasing and inventory with current and historical stock on hand, low stock alerts, and inventory level reports at your fingertips.
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="headline font-weight-black">
                      Cloud Base
                    </div>
                    <div class="title font-weight-regular mt-2">
                      Run your software in Mac, PC, Tablet, or Phone to get access to your inventory or customer balance from anywhere any time around the world.
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="headline font-weight-black">
                      Track Serial Number
                    </div>
                    <div class="title font-weight-regular mt-2">
                      Serial Number Feature allows you to stay top of your game. Don’t lose any money by taking the wrong item back from your customer.
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card tile flat>
              <v-card-text>
                <v-img src="@/assets/Home/screenshot2.png" style="height: 25%;">
                </v-img>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col>
            <v-card color="#FFFAD1" class="pa-10" height="400" flat>
              <v-layout column fill-height justify-center>
                <v-flex shrink>
                  <div class="display-2 font-weight-black text-center">
                    Ready to give a free try
                  </div>
                </v-flex>
                <v-flex class="text-center" shrink>
                  <v-btn
                    class="mt-5"
                    @click="signup"
                    large
                    color="success"
                    >Sign Up Now</v-btn
                  >
                  <!-- <div class="mt-1">No Credit Card Needed</div> -->
                </v-flex>
              </v-layout>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-card tile flat>
              <v-img src="@/assets/Home/screenshot3.png" style="height: 25%;">
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-card flat>
              <v-card-text>
                <v-row class="pa-10">
                  <v-col cols="12">
                    <div class="headline font-weight-black">
                      Lead Management Software
                    </div>
                    <div class="title font-weight-regular mt-2">
                      Keep track of any leads come in your way. Grow your business by not losing any potential customers.
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="headline font-weight-black">
                      Professional Looking Invoice
                    </div>
                    <div class="title font-weight-regular mt-2">
                      Create a professional-looking invoice from smplsale. smplsale allow you to email invoice, packing slip, statement right from the software.
                    </div>
                  </v-col>
                  <!-- <v-col cols="12">
                    <div class="headline font-weight-black">Merge customer</div>
                    <div class="title font-weight-regular mt-2">
                      Mistake happens merge customer feature allow to merge 2
                      customer sale and balance in to one.
                    </div>
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row id="video">
          <v-col>
            <v-card class="pa-10" flat>
              <v-card-text>
                <div class="text-center">
                  <div class="display-2 font-weight-black">
                    Want to learn more? watch videos
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-container grid-list-xl>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <Youtube
                    videoId="udgfcIhuZ1Q"
                    title="Create Lead"
                    videoName="video1"
                    detail="Learn how to create leads, comment on leads"
                  />
                  <!-- <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-card
                      flat
                      :elevation="hover ? 20 : 0"
                      class="mx-auto"
                      height="500"
                    >
                      <v-img
                        class="white--text align-end"
                        height="250px"
                        src="@/assets/Home/video1.png"
                      >
                      </v-img>
                      <v-card-text>
                        <v-card-text class="text--primary">
                          <div>Create Lead</div>

                          <div>Learn how to create leads, comment on leads</div>
                        </v-card-text>

                        <v-card-actions>
                          <v-btn color="orange" text>
                            Watch Video
                          </v-btn>
                        </v-card-actions>
                      </v-card-text>
                    </v-card>
                  </v-hover> -->
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <Youtube
                    videoId="ACLXYU3pzho"
                    title="Create Customer"
                    videoName="video2"
                    detail="Learn how to create new customer in smplsale manage
                            balance"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <Youtube
                    videoId="ARbxXilF_KU"
                    title="Create Product"
                    videoName="video3"
                    detail="How to create new product and set parameter of sales"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <Youtube
                    videoId="e8Wpi59oXXQ"
                    title="Inventory"
                    videoName="video4"
                    detail="Learn to purchase product and how to check inventory balance"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <Youtube
                    videoId="3tHOc-BeZ9U"
                    title="Create Invoice"
                    videoName="video5"
                    detail="Learn how to create invoice."
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-card
                      flat
                      :elevation="hover ? 20 : 0"
                      class="mx-auto"
                      height="500"
                    >
                      <v-img
                        class="white--text align-end"
                        height="250px"
                        src="@/assets/Home/video6@2x.png"
                      >
                      </v-img>

                      <v-card-text>
                        <v-card-text class="text--primary">
                          <div>Invoice Share</div>

                          <div>
                            Learn how you can share invoice with your customer
                          </div>
                        </v-card-text>

                        <v-card-actions>
                          <v-btn color="orange" text>
                            Watch Video
                          </v-btn>
                        </v-card-actions>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
            <v-card class="pa-5" flat>
              <v-card-text>
                <div class="text-center">
                  <div class="title font-weight-black">
                    <v-btn text color="orange" @click="openYoutube">
                      Watch More Videos</v-btn
                    >
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-content>
    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-title class="teal">
          <strong
            class="subheading pointer"
            @click="$router.push({ name: 'contactUs' })"
            >Contact Us</strong
          >

          <v-spacer></v-spacer>
          <v-btn class="mx-4" dark icon @click="openChannel('https://www.facebook.com/smplsaleApp/')">
            <v-icon size="24px">mdi-facebook</v-icon>
          </v-btn>
          <v-btn class="mx-4" dark icon @click="openChannel('https://www.youtube.com/channel/UCiIE6MOvysf-WoexwOOOfTw')">
            <v-icon size="24px">mdi-youtube</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          <v-layout row wrap>
            <v-flex shrink>
              <span
                >Need Help Call:
                <a class="linkTag" href="tel:9513566102"
                  >(951) 356-6102</a
                ></span
              >
            </v-flex>
            <v-spacer> </v-spacer>
            <v-flex shrink>
              {{ new Date().getFullYear() }} —
              <strong>smplsale.com &copy;</strong> |
              <span
                class="pointer"
                @click="$router.push({ name: 'termsandCondition' })"
                >Terms and Condition</span
              >
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>
<script>
import Features from "./components/Features";
import Youtube from "./components/Youtube";

export default {
  data: () => ({
    icons: [
      "fab fa-facebook",
      "fab fa-twitter",
      "fab fa-google-plus",
      "fab fa-linkedin",
      "fab fa-instagram",
    ],
  }),
  // created() {
  //   const loginToken = localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`)
  //   if (loginToken) {
  //     this.$router.push({path: '/home'})
  //   }
  // },
  components: {
    Features,
    Youtube,
  },
  methods: {
    login() {
      window.open("https://app.smplsale.com/login","_self");
    },
    signup() {
      window.open("https://app.smplsale.com/signup","_self");
    },
    openChannel(val) {
      // let routeData =
      //   "https://www.youtube.com/channel/UCiIE6MOvysf-WoexwOOOfTw";
      window.open(val, "_blank");
    },
    openYoutube() {
      // let routeData = this.$router.resolve({
      //   path: `https://www.youtube.com/channel/UCiIE6MOvysf-WoexwOOOfTw?view_as=subscriber`,
      // });
      window.open(
        "https://www.youtube.com/channel/UCiIE6MOvysf-WoexwOOOfTw?view_as=subscriber",
        "_blank"
      );
      // window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style scoped>
.linkTag {
  color: white;
  text-decoration: none !important;
}
</style>
